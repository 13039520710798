//colors
$primary:#1C519A;
$secondary:#3BA53B;

.home-container {
    width: 100vw;
    height: auto;
    background-color: lavender;
    /* margin: auto; */
    /* max-width: 100vw; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #1a202c !important;
    color: white;
    min-height: 100vh;

    h1 {
        font-size: 3em;
    }

    .result-section {
        width: 100%;
        margin-top: 2%;
        .py-24 {
            padding-top: 0;
        }

        .cards-container {

            // width: 60vw;
            .card-item {
                
                > :hover {
                        background-color: #f56565;
                        color: aliceblue;
                      h2{
                          color: #1a202c;
                          font-weight: 700;
                      }  
                        font-weight: 600;
                        border: none;
                        .icon{
                            color: aliceblue;
                        }
                        
                    }

                .px-4 {
                    box-shadow: 0px 7px 25px 5px rgba(0, 0, 0, 0.3);

                   

                }
            }


        }

    }



}

// .total-deaths {

//         & :hover {

//            & + #home-container-id {
//                 background-color: red !important;
//             }
//         }
//     }

.total-deaths {
    & :hover {
        .home-container {
            background: red !important;
        }
    }
}

// #container:hover #cube { background-color: yellow; }

.home-container-id-hovered {
    background-color: red !important;
}

.icon {
    margin-bottom: .75rem;
    color: #f56565;
}