.Search-bar{
    // margin: auto;
    width: 40vw;



    .MuiInputBase-root {
        color: white;
        font-size: 2em;
    }
}


.MuiOutlinedInput-notchedOutline {
    border-color: #F56565 !important;
}


.MuiInputLabel-outlined {
  
    color: aliceblue !important;
}

.MuiSvgIcon-root {
    color: white;
}